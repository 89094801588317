import "@google/model-viewer";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import styles from "./ModelViewer.module.css";
import { ModelViewerElement } from "@google/model-viewer/lib/model-viewer";
import { ModelLoadEvent } from "@google/model-viewer/lib/three-components/ModelScene";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": MyElementAttributes &
        React.HTMLAttributes<HTMLElement> &
        JSX.IntrinsicAttributes & { ref: React.Ref<ModelViewerElement> };
    }
    interface MyElementAttributes {
      src: string;
      ar?: boolean;
      "ar-modes"?: string;
      "ios-src"?: string;
      "shadow-intensity"?: string;
      "camera-controls"?: boolean;
      "auto-rotate"?: boolean;
      "ar-scale"?: string;
      autoplay?: boolean;
      onload?: (e: any) => void;
    }
  }
}

const models: { [key: string]: string } = {
  farmers: "/3d/farmers/croc.gltf",
  idoru: "3d/idoru/idoru.gltf",
  goldenweek: "3d/goldenweek/golden.glb",
  magnum: "3d/magnum/magnum.gltf",
  olay: "3d/olay/model.gltf",
  onrunning: "3d/onrunning/onrunning.gltf",
  santa: "3d/santa/santa.gltf",
  furniture1: "3d/furniture1/furniture1.gltf",
  furniture2: "3d/furniture2/furniture2.gltf",
  ahoy: "3d/ahoy/ahoy.gltf",
  bike: "3d/bike/bike.gltf",
};

let interval: any = null;

const Viewer = ({ model }: { model: string }) => {
  document.getElementById("ar-prompt")!.style.display = "block";
  const [showLoader, setShowLoader] = useState(true);
  const [arStatus, setArStatus] = useState("not-presenting");
  const [arTracking, setArTracking] = useState("not-tracking");

  const [progress, setProgress] = useState(0);

  const viewerRef = React.useRef<ModelViewerElement>(null);

  const [src, setSrc] = useState("");

  // if user agent includes "Variant Launch"
  const isLaunchApp = navigator.userAgent.includes("Variant Launch");
  useEffect(() => {
    setShowLoader(true);
  }, []);

  useEffect(() => {
    const curRef = viewerRef.current;
    if (!viewerRef.current) return;
    //register events
    viewerRef.current!.addEventListener("load", onLoad);
    viewerRef.current!.addEventListener("progress", onProgress);
    viewerRef.current!.addEventListener("ar-status", onARStatus);
    viewerRef.current!.addEventListener("ar-tracking", onARTracking);

    return () => {
      curRef?.removeEventListener("load", onLoad);
      curRef?.removeEventListener("progress", onProgress);
      curRef?.removeEventListener("ar-status", onARStatus);
      curRef?.removeEventListener("ar-tracking", onARTracking);
    };
  }, [viewerRef]);

  const onLoad = (e: any) => {
    console.log("onload");
    if (isLaunchApp) {
      viewerRef.current!.activateAR();
      showPrompt();
      setTimeout(() => {
        hidePrompt();
        console.log("hide fired");
      }, 3800);

      setTimeout(() => {
        const target = document.querySelector("canvas")!;

        const touch = new Touch({
          identifier: Date.now(),
          target: target,
          clientX: 0,
          // set touch to two-thirds of the way down the screen
          clientY: window.innerHeight * 0.66,
        });

        const touchEvent = new TouchEvent("touchstart", {
          touches: [touch],
          view: window,
          cancelable: true,
          bubbles: true,
        });

        target.dispatchEvent(touchEvent);

        const touchEvent2 = new TouchEvent("touchend", {
          touches: [touch],
          view: window,
          cancelable: true,
          bubbles: true,
        });

        target.dispatchEvent(touchEvent2);
      }, 2800);
    }
    //setShowLoader(false);
  };

  const onProgress = (e: any) => {
    setProgress(e.detail.totalProgress);
  };

  const onARStatus = (e: any) => {
    console.log("ar status", e.detail.status);
    setArStatus(e.detail.status);
  };

  const onARTracking = (e: any) => {
    console.log("ar tracking", e.detail.status);
    setArTracking(e.detail.status);
  };

  useEffect(() => {
    if (arStatus === "presenting") {
      setShowLoader(false);
    }
    if (arStatus === "object-placed") {
      //setObjectPlaced(true);
      setShowLoader(false);
      hidePrompt();
      viewerRef.current?.play();
    }
  }, [arStatus]);

  useEffect(() => {}, [arTracking]);

  const showPrompt = () => {
    interval = setInterval(() => {
      document.getElementById("ar-prompt")!.style.display = "block";
    }, 100);
  };

  const hidePrompt = () => {
    clearInterval(interval);
    interval = setInterval(() => {
      document.getElementById("ar-prompt")!.style.display = "none";
    }, 100);
  };

  //update model
  useEffect(() => {
    if (!model || model === "") return;
    setSrc(models[model]);
  }, [model]);

  return (
    <>
      {showLoader && isLaunchApp && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <Oval
            height={128}
            width={128}
            color="#FF008B"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#FF7360"
            strokeWidth={2}
            strokeWidthSecondary={2}
          ></Oval>
          <div
            style={{
              position: "absolute",
              fontWeight: "bold",
              fontSize: "16pt",
            }}
          >
            {Math.floor(progress * 100) + "%"}
          </div>
        </div>
      )}

      <model-viewer
        src={src}
        shadow-intensity="1"
        camera-controls
        ar={true}
        ar-modes="webxr"
        ref={viewerRef}
        ar-scale="auto"
        //className={styles.modelviewer}
      >
        <button id="ar-failure">AR is not tracking!</button>
        <div id="ar-prompt">
          <img src="/img/hand.png" />
        </div>
      </model-viewer>
    </>
  );
};

export default Viewer;
