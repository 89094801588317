import React, { useEffect } from "react";
import "./App.css";
import Viewer from "./components/Viewer";

function App() {
  const [model, setModel] = React.useState("");
  useEffect(() => {
    //get model from url param
    const urlParams = new URLSearchParams(window.location.search);
    const model = urlParams.get("model");
    setModel(model ?? "idoru");
  });
  return (
    <div className="App">
      <Viewer model={model} />
    </div>
  );
}

export default App;
