import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//if localhost use dev key, else use key= from url params
const key =
  window.location.hostname === "localhost"
    ? "l4gA9c3rd8lybGc6ziJaDaFtZCExmApp"
    : "PgCkcRz0dyNiU3cWrOBw3PIB7cFYnuu8";

window.addEventListener("vlaunch-initialized", (e: any) => {
  console.log(e.detail);
  if (e.detail.webXRStatus === "supported") {
    //WebXR is supported
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
    reportWebVitals();
  } else if (e.detail.launchRequired) {
    //We need to use launch to get WebXR
    window.location.href = e.detail.launchUrl;
  } else {
    // no webxr available on this platform at all
  }
});

(window as any).VLaunch.init({
  key,
});
